@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: "DM Sans";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000000;
  text-align: left;
  background-color: #ffffff;
}

.App {
  margin: 0;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-img {
  min-width: 105%;
}

@media (min-width: 1024px) {
  /* header {
    max-height: 80vh;
  } */

  .hero-img {
    margin-bottom: -100px !important;
    margin-right: -150px !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1;
}

.truncate-text {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dstyle .fixed-top {
  z-index: 199 !important;
}

.bstyle .hover-primary {
  color: #161843;
}

.bstyle .hover-primary:hover {
  background-color: #161843;
  color: #ffffff;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.quiz-thumbnail {
  height: 225px;
  object-fit: contain;
  width: auto;
}

.position-relative {
  position: relative;
}

.-mt-1rem {
  margin-top: -1rem;
}

.text-underline {
  text-decoration: underline;
}

.bstyle .hover-danger:hover {
  background-color: #ce2027 !important;
  border: #ce2021 !important;
}

.bstyle .hover-danger button:focus {
  border: #ce2021 !important;
}

.bg-gradient-custom {
  background: #7f7fd5;
  background: -webkit-linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
  background: linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
}

.bg-gradient-custom:hover {
  background: #7f7fd5;
  background: -webkit-linear-gradient(to left, #7f7fd5, #86a8e7, #91eae4);
  background: linear-gradient(to left, #7f7fd5, #86a8e7, #91eae4);
}

.bg-gradient-primary {
  background: #161843;
  background: -webkit-linear-gradient(
    to right,
    #161843,
    #202260,
    #ce2021,
    #ce2027
  );
  background: linear-gradient(to right, #161843, #202260, #ce2021, #ce2027);
}

.bg-gradient-primary-inverse {
  background: #161843;
  background: -webkit-linear-gradient(
    to left,
    #ce2021,
    #161843,
    #202260,
    #ce2027
  );
  background: linear-gradient(to left, #ce2021, #161843, #202260, #ce2027);
}

.bg-gradient-primary-reverse {
  background: #161843;
  background: -webkit-linear-gradient(
    to left,
    #161843,
    #f53c3c,
    #ce2027,
    #202260
  );
  background: linear-gradient(to left, #161843, #ce2021, #ce2027, #202260);
}

.foi-header {
  overflow: hidden;
}

.text-custom-primary {
  color: #161843;
}

.text-custom-danger {
  color: #ce2027;
}

.group .group-hover {
  display: none;
}

.group:hover .group-hover {
  display: block;
  transition: all 200ms ease-in-out;
}

.bstyle .slick-track {
  display: flex !important;
}

.bstyle .slick-slide {
  height: inherit !important;
}

.bstyle .slick-slide > div {
  height: 90%;
}
